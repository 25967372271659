* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-family: 'PT Sans', sans-serif;
}

.home {
    display: flex;
    height: 90vh;
    align-items: center;
    justify-content: center;
    font-size: 3rem;
}

.pagination-container{
    display: flex;
    justify-content: flex-end;
    width: 100%;
    padding: 2% 0;
    margin: auto;
}