.card-container {
  display: flex;
  justify-content: center;
  margin-top: 10px;
  margin-inline: auto;
  margin-bottom: 10px;
  width: 100%;
  min-width: 100%;
}
.inner-img {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  line-height: 60px;
  text-align: center;
  flex: none;
  border: 0.1px solid rgb(198, 193, 193);
  border-radius: 5px;
}
.ant-upload-list-picture-card .ant-upload-list-picture-card-container:first-child .ant-upload-list-item {
  border: 2px solid rgb(125, 9, 9)   !important;
}

.inner-img img {
  position: static;
  display: block;
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.card {
  display: flex;
  justify-content: center;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  padding: 10px;
  width: 100%;
  background-color: white;
}

.image-container {
  position: relative;
  text-align: center;
  color: white;
  width: 100%;
  border: 0.1px solid rgb(198, 193, 193);
  border-radius: 5px;
}
.image-container img {
  position: static;
  display: block;
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.centered {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-46%, -50%);
}

.main-section {
  height: auto;
  min-height: 24vh;
}

.card:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}

.container {
  margin-top: 1px;
  padding: 2px 0px;
  margin-left: 10px;
  width: 100%;
}
.title {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: 100%;
}

.container-min {
  margin-top: 10px;
  padding: 2px 16px;
  float: left;
  width: 100%;
}

.card-disable {
  display: flex;
  justify-content: center;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  padding: 5px;
  min-width: 600px;
}

.container > h4 {
  color: dimgrey;
}

#price {
  color: rgba(40, 127, 184);
}

#time {
  padding-top: 35px;
  display: flex;
  flex-direction: row-reverse;
  color: darkgray;
}

/*mobile settings (below 1000px screen size)*/
/* @media (max-width: 1000px) {
  .card-container {
    margin-bottom: -70px;
    transform: scale(0.7);
    width: 100%;
  }
} */

/*mobile settings (below 800px screen size)*/
@media (max-width: 800px) {
  .home-container {
    width: 100px;
    height: 100px;
  }
  .container-min {
    float: left;
  }
  .overlay-container {
    float: right;
  }
}

.overlay-container {
  position: relative;
  width: 100%;
  min-height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.overlay-container .btn {
  position: absolute;
  top: 40%;
  width: 100%;
  min-height: 100%;
  left: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: translate(-49.2%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;
}
.p-image {
  opacity: 0.5;
  position: relative;
}
.h-image {
  width: 100%;
}

.list {
  max-height: fit-content;
  max-width: fit-content;
  background-color: black;
  list-style-type: none;
}

.home-list {
  max-height: fit-content;
  max-width: fit-content;
  list-style-type: none;
}
