.contact-us {
    background-image: url('/images/skyscraper-view-city-leader-window-frame.jpg');
    background-size: cover;
    text-align: start;
    height: 100vh;
    margin-top: 0.05rem;
}

.contact-container {
    display: flex;
    justify-content: flex-end;
    padding-top: 90px;
    padding-right: 100px;
}

.contact-form {
    width: 33%;
    padding: 25px;
    border-radius: 3px;
    background-color: rgba(40, 127, 184, 0.502);
}

.contact-form>h2 {
    padding: 5px;
}

@media (max-width: 1500px) {
    .contact-container {
        padding-top: 90px;
    }
}

@media (max-width: 800px) {
    .contact-container {
        margin: auto;
        width: 100%;
        padding: 10px;
    }
    .contact-form {
        width: 100%;
        padding: 25px;
        border-radius: 3px;
    }
}