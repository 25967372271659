.banner-wrapper {
  background-image: url('/images/house-1836070_1920.jpg');
  background-size: cover;
  text-align: center;
  height: 90vh;
}

.banner-wrapper > h1 {
  padding-top: 150px;
  color: white;
  font-size: 60px;
}

.banner-wrapper > h2 {
  color: white;
  font-size: 25px;
}

.input_box-container {
  margin: auto;
  width: 70%;
  padding: 10px;
  border-radius: 3px;
  background-color: rgba(40, 127, 184, 0.502);
}
 
.input-box {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding-top: 15px;
}

.reset-box{
  display: flex;
  flex-direction: row-reverse;
  flex-wrap: wrap;
}

.reset-box > div{
  width: 100%;
}

@media (min-width: 1000px) {
  .reset-box > div{
    width: 35%;
  }
}

.input-box > div {
  width: 25%;
  padding-inline: 10px;
}

.input-box > .search-box{
  width: 50%;
  padding-inline: 20px;
}


.slider {
  flex-grow: 10;
}

.slider > h4 {
  color: white;
}

@media (max-width: 800px) {
  .banner-wrapper {
    background-image: url('/images/house-1836070_1920.jpg');
    background-size: cover;
    text-align: center;
    height: 700px;
  }

  .input_box-container {
    margin: auto;
    width: 100%;
    padding: 10px;
    background-color: rgba(40, 127, 184, 0.502);
  }

  .input-box {
    flex-direction: column;
  }

  .input-box > div {
    width: 100%;
  }
  .input-box > .search-box{
    width: 100%;
    padding-inline: 10px;
    padding-bottom: 10px;
  }

  .banner-wrapper > h1 {
    padding-top: 50px;
    color: white;
    font-size: 30px;
  }
  
  .banner-wrapper > h2 {
    color: white;
    font-size: 20px;
  }
}

.search-bar-container{
  display: flex;
  flex-direction: row;
  width: 100%;
}
