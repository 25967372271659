* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "PT Sans", sans-serif;
}

body {
  background: #d9edff;
}
html {
  scroll-behavior: smooth;
}
.thumbnails {
  max-height: 200px;
}
strong {
  font-weight: bold;
}

.ant-page-header-heading-title {
 
  white-space: unset !important;

}